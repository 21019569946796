<template>
  <el-row :gutter='10' style="margin-top:20px;">
    <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" class="ul">
      <div class="dialogCard">
        <div class="subBox">
          <div class="title">用户互动</div>
          <div class="subTitle">更新时间：{{nowTime}}</div>
        </div>

        <el-row>
          <el-col :lg="6" :md="12" :sm="12" :xl="6" :xs="24" class="ul" v-for="(item, index) in interactionData" :key="index">
            <p class='smTitle'>{{item.name}}</p>
            <div v-if="loading">加载中...</div>
            <template v-else>
              <vab-count :decimals="item.countConfig.decimals" :duration="item.countConfig.duration" :end-val="item.countConfig.endVal" :prefix="item.countConfig.prefix" :separator="item.countConfig.separator" :start-val="item.countConfig.startVal"
                style="font-size:26px;color:#1D2129;font-weight:bold;" />
              <span style="font-size:16px;color:#4e5969">{{item.countConfig.suffix}}</span>
            </template>
          </el-col>

        </el-row>
      </div>
    </el-col>
  </el-row>

</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import _ from 'lodash'
import VabChart from '@/extra/VabChart'
import VabCount from '@/extra/VabCount'

export default defineComponent({
  components: {
    VabChart,
    VabCount,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
    nowTime: {
      type: String,
      default: '',
    },
    meetingType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    watch(
      () => props.data,
      (newVal, oldVal) => {
        state.interactionData[0].countConfig.endVal = newVal.articleReadTimes
        state.interactionData[1].countConfig.endVal =
          newVal.meetingForumVisitTimes
        state.interactionData[2].countConfig.endVal = newVal.followNum
        state.interactionData[3].countConfig.endVal = newVal.collectNum
        state.interactionData[4].countConfig.endVal = newVal.shareNum
        state.interactionData[5].countConfig.endVal = newVal.askNum
        state.interactionData[6].countConfig.endVal = newVal.fileVisitTimes
        state.interactionData[7].countConfig.endVal = newVal.fileDownloadTimes
        state.interactionData[8].countConfig.endVal = newVal.interestForumNum 
        state.interactionData[9].countConfig.endVal = newVal.collectForumNum
        state.interactionData[10].countConfig.endVal = newVal.initiateInvitationNum
        state.interactionData[11].countConfig.endVal = newVal.acceptInvitationNum

        setTimeout(() => {
          state.loading = false
        }, 100)
      }
    )
    const state = reactive({
      loading: true,
      interactionData: [
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '文章阅读数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '论坛访问数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '用户关注',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '用户收藏',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '用户分享',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '提问数量',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '文件查看次数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '文件下载次数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人次',
            separator: ',',
            duration: 3000,
          },
          name: '关注论坛',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人次',
            separator: ',',
            duration: 3000,
          },
          name: '收藏论坛',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '发起互动邀约',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '接受互动邀约',
          colCount: 8,
        },
      ],
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>


<style lang="scss" scoped>
.dialogCard {
  width: 100%;
  min-height: 174px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  .subBox {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-bottom: 4px;
    }
    .subTitle {
      font-size: 12px;
      color: #4e5969;
    }
  }

  .ul {
    margin-top: 15px;
    .smTitle {
      font-size: 16px;
      color: #1d2129;
    }
  }
}
</style>